.feed {
  max-width: 16cm;
  margin: 0 auto;
  text-align: center;
  padding: 8px;
}

.logo {
  font-family: "Montserrat Alternates", Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin: 8px 0px;
  text-decoration: none;
  color: #ec8888;
}

.clean-link {
  text-decoration: none;
  color: inherit;
}

.std-link {
  text-decoration: underline;
  color: blue;
}

.navbar {
  padding: 8px 0px;
}

